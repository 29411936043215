export const environment = {
    production: false,
    locationURL: 'https://ordereasy-qa.gofrugalretail.com/',
    alertURL: 'https://ordereasy-qa.gofrugalretail.com/Alert/',
    ecomURL: 'https://ordereasy-qa.gofrugalretail.com/ecommerce/',
    ondcURL: 'https://ordereasy-qa.gofrugalretail.com/ondc/',
    accountURL: 'https://ordereasy-qa.gofrugalretail.com/accounts/',
    paymentURL: 'https://ordereasy-qa.gofrugalretail.com/payment/',
    samURL: 'https://labtest.gofrugal.com/',
    deliveryURL: 'https://ordereasy-qa.gofrugalretail.com/delivery-service/api/v1/',
    smartReports: 'https://ordereasy-qa.gofrugalretail.com/reports/#/reports?',
    samAddressDetailURL: 'https://labtest.gofrugal.com/ismile/',
    analyticsURL: 'https://ordereasy-qa.gofrugalretail.com/app-analytics/api/v1/',
    srDashboard: 'https://ordereasy-qa.gofrugalretail.com/reports/#/dashBoard?',
    storePurchase: 'http://labteststore.gofrugal.com/service_index.php?',
    skewCode: '742',
    skewName: 'OrderEasy',
    validAddons: ['742', '538', '744'],
    defaultUrl: 'Integration/ecommerce/sr-dashboard/ordereasy/ecommerce/gft_oe',
    mainHeadnig: 'Your online order management',
    secondHeading: 'platform connected with delivery management to gain more orders and expand your customer base',
    bgColor: '#F9F9FB',
    orderEasyMapAPIKey:'AIzaSyCgHVU5ErnFea-yZX3d32t2yJHZ6PlF8GQ',
    goTrackerMapAPIKey:'AIzaSyBzwRB9ZGyeHjtuN0vKBozoOy0SZwkp-OA'
};
